<template>

  <div class="page-container">

    <img class="logo" src="@/assets/images/esprezzo-logo.png" />

    <div class="container">
      <img class="satellite" src="@/assets/images/satellite.png" />
      <h3>Dispatch is currently down for scheduled maintenance.</h3>
      <p>We’ll be back soon.</p>
    </div>

  </div>

</template>

<script>

  export default {}

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/logged-out-page.styl'

  .container
    max-width: 48rem

    +breakpoint(sm)
      @apply p-16

  .satellite
    @apply w-48
    @apply h-48
    @apply mb-4
    @apply mx-auto

  p
    @apply text-xl
    @apply font-bold
    @apply text-gray-600

    +breakpoint(sm)
      @apply text-3xl

</style>
